import {
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import cn from 'classnames';
import Home from './components/Home';
import Pictures from './components/Pictures';
import Calendar from './components/Calendar';
import Description from './components/Description';
import Contact from './components/Contact';
import Feedback from './components/Feedback';
import text from './i8n/text.js';
import styles from './App.module.css';

function App() {
  const { pathname } = useLocation();

  let language = 'nl';
  let currentPage = pathname.split('/').pop();
  console.log(pathname);
  if (pathname.search('_fr') > -1) {
    language = 'fr';
    currentPage = currentPage.slice(0, -3);
  } else if (pathname.search('_en') > -1) {
    language = 'en';
    currentPage = currentPage.slice(0, -3);
  }
  console.log(currentPage);
  const pathSuffix = language === 'nl' ? '' : `_${language}`;
  return (
    <div>
			<div className={styles.header}>
		    <div className={styles.headerContainer}>
			    <div className={styles.titleContainer}>
		        <a href={`/${pathSuffix}`}>
    			    <h3>
							  Duvetorre
								<span> 46</span>
							</h3>
						  <span className={styles.subtitle}>Zeedijk - Nieuwpoort</span>
		        </a>
	  		  </div>
          <nav className={styles.navbar}>
            <ul className={styles.menuContainer}>
              <li className={styles.menu}>
                <Link className={styles.menuLink} to={`/${pathSuffix}`}>{text.app.home[language]}</Link>
              </li>
              <li className={styles.menu}>
                <Link className={styles.menuLink} to={`/description${pathSuffix}`}>{text.app.description[language]}</Link>
              </li>
              <li className={styles.menu}>
                <Link className={styles.menuLink} to={`/pictures${pathSuffix}`}>{text.app.pictures[language]}</Link>
              </li>
              <li className={styles.menu}>
                <Link className={styles.menuLink} to={`/contact${pathSuffix}`}>{text.app.contact[language]}</Link>
              </li>
            </ul>
            <Link className={cn(styles.menu, styles.menuCalendar, styles.menuLink)} to={`/calendar${pathSuffix}`}>{text.app.calendar[language]}</Link>
            <div className={styles.language}>
              <a href={`/${currentPage}`}>
                NL
              </a>
              <a className={styles.languageItem}  href={`/${currentPage}_fr`}>
                FR
              </a>
              <a href={`/${currentPage}_en`}>
                EN
              </a>
            </div>
          </nav>
        </div>
		  </div>
	    <div className={styles.body}>
        <Switch>
          <Route path="/description*">
            <Description language={language} />
          </Route>
          <Route path="/pictures*">
            <Pictures language={language} />
          </Route>
          <Route path="/calendar*">
            <Calendar language={language} />
          </Route>
          <Route path="/contact*">
            <Contact language={language} />
          </Route>
          <Route path="/feedback*">
            <Feedback language={language} />
          </Route>
          <Route path="/*">
            <Home language={language} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;