const text =
  {
    app: {
      home: {
        nl: "Home",
        fr: "Home",
        en: "Home"
      },
      description: {
        nl: "Beschrijving",
        fr: "Description",
        en: "Description"
      },
      pictures: {
        nl: "Foto's",
        fr: "Photos",
        en: "Pictures"
      },
      contact: {
        nl: "Contact",
        fr: "Contact",
        en: "Contact"
      },
      calendar: {
        nl: "Kalender",
        fr: "Calendrier",
        en: "Calendar"
      },
      lang: {
        nl: "Nederlands",
        fr: "Français",
        en: "English"
      }
    },
    home: {
      meta: {
        title: {
          nl: "Duvetorre 46 - Zeedijk Nieuwpoort - vakantie-appartement tot 6 personen",
          fr: "Duvetorre 46 - Zeedijk Nieuwpoort - appartement de vacances jusqu'à 6 personnes",
          en: "Duvetorre 46 - Zeedijk Nieuwpoort - holiday apartment for up to 6 persons"
        },
        description: {
          nl: "Kleurrijk vakantie-appartement, gelegen op de Zeedijk te Nieuwpoort, 5de verdieping, van alle comfort voorzien, en kan tot 6 personen ontvangen",
          fr: "Appartement de vacances joyeux, situé sur la “Zeedijk” à Nieuport, au 5ème étage, équipé de tout confort, jusqu'à 6 personnes",
          en: "Colorful holiday apartment, located on the “Zeedijk” in Nieuwpoort, 5th floor, equipped with every possible comfort, for up to 6 persons"
        }
      },
      pic1: {
        nl: "Kleurrijke woonkamer",
        fr: "Appartement de vacances joyeux",
        en: "Colorful living room"
      },
      pic2: {
        nl: "Adembenemende zeezichten",
        fr: "Vues surprenantes",
        en: "Breathtaking views"
      },
      pic3: {
        nl: "Eindeloze strandwandelingen",
        fr: "Ballades de plage sans fins",
        en: "Endless hiking on the beach"
      }
    },
    description: {
      meta: {
        title: {
          nl: "Duvetorre 46 - Zeedijk Nieuwpoort - vakantie-appartement tot 6 personen",
          fr: "Duvetorre 46 - Zeedijk Nieuwpoort - appartement de vacances jusqu'à 6 personnes",
          en: "Duvetorre 46 - Zeedijk Nieuwpoort - holiday apartment for up to 6 persons"
        },
        description: {
          nl: "",
          fr: "",
          en: ""
        }
      },
    },
    pictures: {
      meta: {
        title: {
          nl: "Duvetorre 46 - Zeedijk Nieuwpoort - vakantie-appartement tot 6 personen",
          fr: "Duvetorre 46 - Zeedijk Nieuwpoort - appartement de vacances jusqu'à 6 personnes",
          en: "Duvetorre 46 - Zeedijk Nieuwpoort - holiday apartment for up to 6 persons"
        },
        description: {
          nl: "",
          fr: "",
          en: ""
        }
      },
      living: {
        nl: "woonkamer",
        fr: "salle de séjour",
        en: "living room"
      },
      kitchen: {
        nl: "keuken",
        fr: "cuisine",
        en: "kitchen"
      },
      bedroom1: {
        nl: "slaapkamer 1",
        fr: "chambre à coucher 1",
        en: "bedroom 1"
      },
      bedroom2: {
        nl: "slaapkamer 2",
        fr: "chambre à coucher 2",
        en: "bedroom 2"
      },
      bathroom: {
        nl: "badkamer",
        fr: "salle de bains",
        en: "bathroom"
      },
      view: {
        nl: "zicht",
        fr: "vue",
        en: "view"
      },
      garage: {
        nl: "garage",
        fr: "garage",
        en: "garage"
      }
    },
    contact: {
      meta: {
        title: {
          nl: "Duvetorre 46 - Zeedijk Nieuwpoort - vakantie-appartement tot 6 personen",
          fr: "Duvetorre 46 - Zeedijk Nieuwpoort - appartement de vacances jusqu'à 6 personnes",
          en: "Duvetorre 46 - Zeedijk Nieuwpoort - holiday apartment for up to 6 persons"
        },
        description: {
          nl: "",
          fr: "",
          en: ""
        }
      },
    },
    calendar: {
      meta: {
        title: {
          nl: "Duvetorre 46 - Zeedijk Nieuwpoort - vakantie-appartement tot 6 personen",
          fr: "Duvetorre 46 - Zeedijk Nieuwpoort - appartement de vacances jusqu'à 6 personnes",
          en: "Duvetorre 46 - Zeedijk Nieuwpoort - holiday apartment for up to 6 persons"
        },
        description: {
          nl: "",
          fr: "",
          en: ""
        }
      },
      weekend: {
        nl: "Weekend",
        fr: "Weekend",
        en: "Weekend",
        description: {
          nl: "vrijdag > maandag (3 nachten)",
          fr: "vendredi > lundi (3 nuits)",
          en: "Friday > Monday (3 nights)"
        }
      },
      midweek: {
        nl: "Midweek",
        fr: "Milieu de semaine",
        en: "Midweek",
        description: {
          nl: "maandag > vrijdag (4 nachten)",
          fr: "lundi > vendredi (4 nuits)",
          en: "Monday > Friday (4 nights)"
        }
      },
      week: {
        nl: "Week",
        fr: "Semaine",
        en: "Week",
        description: {
          nl: "maandag > maandag of vrijdag > vrijdag (7 nachten)",
          fr: "lundi > lundi ou vendredi > vendredi (7 nuits)",
          en: "Monday > Monday or Friday > Friday (7 nights)"
        }
      },
      schoolHolidays: {
        krokus: {
          nl: "Krokusvakantie",
          fr: "Vacances de carnaval",
          en: "Carnival Holidays",
        },
        pasen: {
          nl: "Paasvakantie",
          fr: "Vacances de pâques",
          en: "Easter Holidays",
        },
        zomer: {
          nl: "Zomervakantie",
          fr: "Vacances d'été",
          en: "Summer Holidays",
        },
        herfst: {
          nl: "Herfstvakantie",
          fr: "Vacances automne",
          en: "Autumn Holidays",
        },
        kerst: {
          nl: "Kerstvakantie",
          fr: "Vacances de Noël",
          en: "Christmas Holidays",
        }
      },
      notApplicable: {
        nl: "n.v.t.",
        fr: "N/A",
        en: "N/A",
      },
      except: {
        nl: "behalve",
        fr: "excepté",
        en: "except for",
      },
      arrival: {
        nl: "Aankomst vanaf 15u",
        fr: "Arrivée àpd 15h",
        en: "Arrival from 3 PM",
      },
      departure: {
        nl: "Vertrek vóór 11u",
        fr: "Départ avant 11h",
        en: "Departure before 11 AM",
      },
      garage: {
        nl: "Gebruik garagebox inbegrepen",
        fr: "Utilisation box garage incluse",
        en: "Use of garage box included",
      },
      animals: {
        nl: "Wegens allergie van de eigenaars, geen huisdieren toegelaten",
        fr: "A cause d’allergies des propriétaires, pas d’animaux domestiques",
        en: "Due to allergies of the owners, no pets allowed",
      },
      cleaning: {
        nl: "",
        fr: "",
        en: "",
      },
      specials: {
        nl: "Afwijkingen mogelijk",
        fr: "Dérogations possible",
        en: "Derogations are possible",
      },
      discount: {
        nl: "Korting voor langere periodes",
        fr: "Réduction possible pour séjour plus longues",
        en: "Discounts possible for longer stays",
      },
      contact: {
        nl: "Contacteer ons met specifieke vragen",
        fr: "Contactez-nous avec vos questions spécifiques",
        en: "Contact us for specific questions",
      },
      book: {
        nl: "Boek hier!",
        fr: "Réservez ici!",
        en: "Book here!",
      }
    },
    feedback: {
      meta: {
        title: {
          nl: "Duvetorre 46 - Zeedijk Nieuwpoort - vakantie-appartement tot 6 personen",
          fr: "Duvetorre 46 - Zeedijk Nieuwpoort - appartement de vacances jusqu'à 6 personnes",
          en: "Duvetorre 46 - Zeedijk Nieuwpoort - holiday apartment for up to 6 persons"
        }
      },
      submitted: {
        nl: "Bedankt voor de feedback!",
        fr: "Merci bien pour votre feedback!",
        en: "Thanks for the feedback!",
      },
      feedback1: {
        nl: "Wij hopen dat u tevreden terugkijkt op uw verblijf in",
        fr: "Nous espèrons que vous ayiez passé de bons moments à",
        en: "We hope you had some lovely times at",
      },
      feedback2: {
        nl: "Uw waardevolle feedback kan ons helpen onze service in de toekomst te verbeteren.",
        fr: "Votre feedback nous sert d’améliorer notre service.",
        en: "Your valuable feedback helps us improve our service.",
      },
      feedback3: {
        nl: "Alvast bedankt!",
        fr: "Merci d’avance!",
        en: "Thanks in advance!",
      },
      name: {
        nl: "Naam",
        fr: "Nom",
        en: "Name",
      },
      arrival: {
        nl: "Aankomst",
        fr: "Arrivée",
        en: "Arrival",
      },
      departure: {
        nl: "Vertrek",
        fr: "Départ",
        en: "Departure",
      },
      info: {
        nl: "Heeft u genoeg informatie gekregen vóór uw aankomst?",
        fr: "Avez-vous reçu suffisament d’information avant votre arrivée?",
        en: "Did you receive sufficient information before your arrival?",
      },
      clean: {
        nl: "Was het appartement schoon bij aankomst?",
        fr: "L’appartement était assez propre lors de votre arrivée?",
        en: "Was the apartment clean on arrival?",
      },
      bookAgain: {
        nl: "Zou u in de toekomst nog eens willen boeken?",
        fr: "Vous réserverez à nouveau dans le futur?",
        en: "Would you book again?",
      },
      surelyNot: {
        nl: "zeker niet",
        fr: "certainement pas",
        en: "absolutely not",
      },
      ratherNot: {
        nl: "eerder niet",
        fr: "plutôt pas",
        en: "rather not",
      },
      ratherYes: {
        nl: "eerder wel",
        fr: "plutôt oui",
        en: "rather well",
      },
      surelyYes: {
        nl: "zeker wel",
        fr: "certainement oui",
        en: "absolutely",
      },
      remarks: {
        nl: "Opmerkingen/tips",
        fr: "Remarques/suggestions",
        en: "Remarks/tips",
      },
      submit: {
        nl: "Verzend",
        fr: "Envoyer",
        en: "Submit",
      },
    }
  }

export default text;
