import axios from "axios";

const CALENDAR_ID = 'e2tf22p8f3rfj7l1s22og7vmn0@group.calendar.google.com';
const API_KEY = 'AIzaSyCnEsiLy8hstHwc19nZlShBzhgU99v18No';

export async function getEvents(timeMin, timeMax) {
  const res = await axios.get(
	  `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events`, 
		{ params: { key: API_KEY, timeMin, timeMax, singleEvents: true, orderBy: "startTime" } }
	);    
	
	return res.data.items;
}