import React, {useState} from "react";
import useMetaTags from 'react-metatags-hook';
import{ send } from 'emailjs-com';
import styles from './Feedback.module.css'
import text from "../i8n/text";

const initFormState = {
	name: "",
	arrival: "",
	departure: "",
	infoArrival: "",
	clean: "",
	bookAgain: "",
	remarks: "",
}

function Feedback({ language }) {
	useMetaTags({
		title: text.feedback.meta.title[language],
	});

	const [formData, setFormData] = useState(initFormState);
	const [submitStatus, setSubmitStatus] = useState();

	const handleChange = e => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value.trim()
		});
	};

	const sendEmail = e => {
		e.preventDefault();
		console.log(formData);
		send('service_6shv6va', 'template_61anypi', formData, 'user_TRJL3VoRiCUyHaZ4eFxPq')
			.then((result) => {
				console.log(result.text);
			}, (error) => {
				console.log(error.text);
			});
		setSubmitStatus(text.feedback.submitted[language]);
	}

	const renderRadioGroup = (label, name, options) => (
		<React.Fragment>
			<label className={styles.questionLabel}>{label}</label>
			<div className={styles.radioGroup}>
				{options.map(option =>
					<div>
					  <input type="radio" id={`${name}-${option.name}`} name={name} value={option.label} onChange={handleChange} />
					  <label htmlFor={`${name}-${option.name}`}>{option.label}</label>
				  </div>
				)}
			</div>
		</React.Fragment>
	);

	return (
		<div>
			<img className={styles.topImage} src="/images/10.jpg" alt="" />
			<div className={styles.container}>
				<p>{text.feedback.feedback1[language]} Duvetorre<span className={styles.fortySix}>46</span> .</p>
				<p>{text.feedback.feedback2[language]}</p>
				<p>{text.feedback.feedback3[language]}</p>
				<form className={styles.evalForm} onSubmit={sendEmail}>
					<label className={styles.questionLabel}>{text.feedback.name[language]}</label>
					<input type="text" name="name" onChange={handleChange} />
					<div className={styles.dates}>
						<label className={styles.questionLabel}>{text.feedback.arrival[language]}</label>
						<input type="date" name="arrival" onChange={handleChange} />
						<label className={styles.questionLabel}>{text.feedback.departure[language]}</label>
						<input type="date" name="departure" onChange={handleChange} />
					</div>
					{renderRadioGroup(text.feedback.info[language], "infoArrival", [
						{ name: "return_1", label: text.feedback.surelyNot[language]},
						{ name: "return_2", label: text.feedback.ratherNot[language]},
						{ name: "return_3", label: text.feedback.ratherYes[language]},
						{ name: "return_4", label: text.feedback.surelyYes[language]},
					])}
					{renderRadioGroup(text.feedback.clean[language], "clean", [
						{ name: "return_1", label: text.feedback.surelyNot[language]},
						{ name: "return_2", label: text.feedback.ratherNot[language]},
						{ name: "return_3", label: text.feedback.ratherYes[language]},
						{ name: "return_4", label: text.feedback.surelyYes[language]},
					])}
					{renderRadioGroup(text.feedback.bookAgain[language], "bookAgain", [
						{ name: "return_1", label: text.feedback.surelyNot[language]},
						{ name: "return_2", label: text.feedback.ratherNot[language]},
						{ name: "return_3", label: text.feedback.ratherYes[language]},
						{ name: "return_4", label: text.feedback.surelyYes[language]},
					])}
					<label className={styles.questionLabel}>{text.feedback.remarks[language]}</label>
					<textarea name="remarks" rows="10" onChange={handleChange} />
					<div className={styles.submit}>
				  	<input type="submit" value={text.feedback.submit[language]} />
				  	<span>{submitStatus}</span>
					</div>
				</form>
			</div>
		</div>
	);
}

export default Feedback;