import styles from './Description.module.css';
import useMetaTags from 'react-metatags-hook'
import text from "../i8n/text";

function Description({ language }) {
	useMetaTags({
		title: text.description.meta.title[language],
		description: text.description.meta.description[language],
	});
	const getDescription = () => {
		if (language === 'nl') {
			return(
				<div className={styles.description}>
					<i>Blik op oneindig: bruisende baren, het zilte zand, een schouwtoneel van veranderende luchten en wolken…</i><br /><br />
					Dit kleurrijke vakantie-appartement is gelegen op de <strong>Zeedijk 46 te Nieuwpoort, 5de verdieping</strong>, van alle comfort voorzien, en kan tot <strong>6 personen</strong> ontvangen.<br /><br />
					In de aanbieding:<br />
					<ul>
						<li>een lichte gezellige woonkamer, voorzien van warme kleuren, met <strong>180° zeezicht</strong></li>
						<li><strong>49" smart TV</strong> met TV Vlaanderen voor live TV. Voorzien voor Netflix, Apple TV, Disney+, Spotify, YouTube, Stievie, Amazon Video en Hulu (eigen account gebruiken)</li>
						<li>praktische keukenhoek (vitrokeramische kookplaat, oven, dampkap, koelkast met klein ijsvakje, microgolfoven, vaatwasser, Nespresso, Senseo, percolator)</li>
						<li>inkomhal met kapstok en schoenenbankje, en een ruime dressingkast</li>
						<li>hoofdslaapkamer met <strong>dubbel bed (160 cm)</strong>, plaats voor <strong>baby-bedje</strong> (voorzien in kast), balkon met ochtendzon</li>
						<li>2de slaapkamer met <strong>dubbel bed (160 cm)</strong>, <strong>stapelbed</strong> en eigen douche en wastafel</li>
						<li>badkamer met douche in bad, wastafel en toilet</li>
						<li>apart toilet</li>
						<li>gratis <strong>WiFi</strong></li>
						<li><strong>garagebox</strong> (2,75m x 5,1m) op 100 meter van het appartement inbegrepen</li>
					</ul>
					Naast de ingang op de zeedijk, beschikt dit appartement over een ingang in de winkelstraat (Albert I-laan 194), wat gerieflijk is bij aankomst en vertrek, en de mogelijkheid biedt te shoppen zonder weg te waaien als de Beauforts toenemen…
				</div>)
		} else if (language === 'fr') {
			return(
				<div className={styles.description}>
					<i>Regard sur l'infini: les vagues bouillonnantes, le sable salé, un théâtre de ciels nuageux changeants...</i><br /><br />
					Cet appartement de vacances plein de coleurs est situé au <strong>Zeedijk 46 à Nieuport, au 5ème étage</strong>, équipé de tout confort et peut accueillir jusqu'à <strong>6 personnes</strong>.<br /><br />
					En offre:<br />
					<ul>
						<li>un salon lumineux et chaleureux, aux couleurs vives, avec <strong>vue de 180° sur la mer</strong></li>
						<li><strong>Smart TV de 49"</strong> avec TV Vlaanderen pour live télévision. Avec Netflix, Apple TV, Disney +, Spotify, YouTube, Stievie, Amazon Video et Hulu (utilisez votre propre compte)</li>
						<li>coin cuisine pratique (taques vitrocéramiques, four, hotte, réfrigérateur avec petit compartiment glaçons, micro-ondes, lave-vaisselle, Nespresso, Senseo, percolateur)</li>
						<li>hall d'entrée avec porte-manteau et banc à chaussures, et une grande armoire de dressing</li>
						<li>chambre à coucher principale avec <strong>lit double (160 cm)</strong>, espace pour <strong>lit bébé</strong> (fourni dans le placard), balcon avec soleil du matin</li>
						<li>2ème chambre à coucher avec <strong>lit double (160 cm)</strong>, <strong>lit superposé</strong> et douche et lavabo privés</li>
						<li>salle de bain avec douche dans la baignoire, lavabo et toilette</li>
						<li>toilette séparée</li>
						<li><strong>WiFi</strong> gratuit</li>
						<li><strong>box garage</strong> (2,75m x 5,1m) à 100 mètres de l'appartement inclus</li>
					</ul>
					En plus de l'entrée sur la digue, cet appartement dispose d'une entrée dans la rue commerçante (Albert I-laan 194), ce qui est pratique à l'arrivée et au départ, et offre la possibilité de faire du shopping sans être soufflés quand les Beauforts montent...
				</div>)
		} else if (language === 'en') {
			return(
				<div className={styles.description}>
					<i>Endless seascapes: rolling waves, the salty sand, a theater of changing skies and clouds...</i><br /><br />
					This colourful holiday apartment is situated on the <strong>seawall in Nieuwpoort, on the 5th floor</strong>, equipped with every possible comfort, and can accommodate up to <strong>6 persons</strong>.<br /><br />
					What we offer:<br />
					<ul>
						<li>a bright, cozy living room, decorated with warm colours and <strong>180° sea view</strong></li>
						<li><strong>49" smart TV</strong> with TV Vlaanderen for live TV, suited for Netflix, Apple TV, Disney+, Spotify, YouTube, Stievie, Amazon Video and Hulu (use your own accounts)</li>
						<li>practical kitchen area (vitroceramic cooktop, oven, extractor hood, fridge with small ice compartment, microwave, dishwasher, Nespresso, Senseo, percolator)</li>
						<li>entrance hall with coat rack, shoe bench and a spacious dressing cupboard</li>
						<li>master bedroom with <strong>queen size bed (160 cm)</strong>, place for <strong>baby cot</	strong> (provided in cupboard), balcony with morning sun</li>
						<li>2nd bedroom with <strong>queen size bed (160 cm)</strong>, <strong>bunk bed</strong>, shower and washstand </li>
						<li>bathroom with shower in bath, washstand and toilet</li>
						<li>separate toilet</li>
						<li>free <strong>WiFi</strong></li>
						<li><strong>garage box</strong> (2,75m x 5,1m) at 100 meters from the apartment included</li>
					</ul>
					In addition to the entrance on the seawall, this apartment has an entrance directly in the shopping street (Albert I-laan 194), which is convenient on arrival and departure, and offers the opportunity to shop without being blown away when the Beauforts increase ...
				</div>)
		}
	}
	
	return ( 
	  <div>
		  <img className={styles.topImage} src="/images/11.jpg" alt="" />
			{getDescription()}
	 	</div>
  );
}

export default Description;