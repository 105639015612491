import useMetaTags from 'react-metatags-hook'
import styles from './Contact.module.css'
import text from "../i8n/text";

function Contact({ language }) {
	useMetaTags({
		title: text.contact.meta.title[language],
		description: text.contact.meta.description[language],
	});
	return (
		<div>
			<img className={styles.topImage} src="/images/10.jpg" alt="" />
			<div className={styles.container}>
				<img src="/images/email.png" alt="" /><br />
				<a href="mailto:info@duvetorre46.be">info@duvetorre46.be</a><br /><br />
				<img src="/images/telephone.png" alt="" /><br />
				0477/56.15.69<br /><br />
				<img src="/images/facebook.png" alt="" /><br />
				<a href="https://www.facebook.com/Duvetorre-46-100242541862217" target="_blank" rel="noreferrer">Facebook</a><br /><br />
				<img src="/images/messenger.png" alt="" /><br />
				<a href="https://m.me/100242541862217" target="_blank" rel="noreferrer">Messenger</a>
			</div>
		</div>
	);
}

export default Contact;