import React from 'react';
import useMetaTags from 'react-metatags-hook'
import Gallery from 'react-grid-gallery';
import { useMediaPredicate } from "react-media-hook";
import './Pictures.module.css';
import text from '../i8n/text.js';

function Pictures({ language }) {
	useMetaTags({
		title: text.pictures.meta.title[language],
		description: text.pictures.meta.description[language],
	});
	const isSmall = useMediaPredicate("(max-width: 600px)");

	const thumbnailWidth = isSmall ? 200 : 320;
	const thumbnailHeight = isSmall ? 150 : 240;
	
	const IMAGES = [
		{
			src: "/images/living_1.jpg",
			thumbnail: "/images/living_1_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "/images/living_2.jpg",
			thumbnail: "/images/living_2_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "/images/living_3.jpg",
			thumbnail: "/images/living_3_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
    },
		{
			src: "/images/living_4.jpg",
			thumbnail: "/images/living_4_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "/images/living_5.jpg",
			thumbnail: "/images/living_5_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "/images/living_6.jpg",
			thumbnail: "/images/living_6_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
    },
		{
			src: "/images/living_7.jpg",
			thumbnail: "/images/living_7_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "/images/living_8.jpg",
			thumbnail: "/images/living_8_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "/images/living_9.jpg",
			thumbnail: "/images/living_9_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.living[language]
		},
		{
			src: "/images/keuken_1.jpg",
			thumbnail: "/images/keuken_1_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.kitchen[language]
    },
		{
			src: "/images/keuken_2.jpg",
			thumbnail: "/images/keuken_2_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.kitchen[language]
		},
		{
			src: "/images/slaapkamer1_1.jpg",
			thumbnail: "/images/slaapkamer1_1_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom1[language]
		},
		{
			src: "/images/slaapkamer1_2.jpg",
			thumbnail: "/images/slaapkamer1_2_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom1[language]
    },
		{
			src: "/images/slaapkamer2_1.jpg",
			thumbnail: "/images/slaapkamer2_1_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom2[language]
		},
		{
			src: "/images/slaapkamer2_2.jpg",
			thumbnail: "/images/slaapkamer2_2_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom2[language]
		},
		{
			src: "/images/slaapkamer2_3.jpg",
			thumbnail: "/images/slaapkamer2_3_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom2[language]
    },
		{
			src: "/images/slaapkamer2_4.jpg",
			thumbnail: "/images/slaapkamer2_4_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bedroom2[language]
		},
		{
			src: "/images/badkamer_1.jpg",
			thumbnail: "/images/badkamer_1_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.bathroom[language]
		},
		{
			src: "/images/zicht_1.jpg",
			thumbnail: "/images/zicht_1_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.view[language]
    },
		{
			src: "/images/zicht_2.jpg",
			thumbnail: "/images/zicht_2_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.view[language]
		},
		{
			src: "/images/zicht_3.jpg",
			thumbnail: "/images/zicht_3_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.view[language]
		},
		{
			src: "/images/zicht_4.jpg",
			thumbnail: "/images/zicht_4_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.view[language]
		},
		{
			src: "/images/zicht_5.jpg",
			thumbnail: "/images/zicht_5_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.view[language]
		},
		{
			src: "/images/facade.jpg",
			thumbnail: "/images/facade_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.view[language]
		},
		{
			src: "/images/garage.jpg",
			thumbnail: "/images/garage_thumb.jpg",
			thumbnailWidth,
			thumbnailHeight,
			caption: text.pictures.garage[language]
		}
	];

	return (
		 <Gallery
			 className="container"
			 images={IMAGES}
			 enableImageSelection={false}
			 backdropClosesModal={true}
			 rowHeight={isSmall ? 109 : 200}
		 />
  );
}

export default Pictures;