export function createUTCDateFromString(str) {
  const date = new Date(str);
	const y = date.getUTCFullYear();
  const m = date.getUTCMonth();
  const d = date.getUTCDate();
	return new Date(Date.UTC(y,m,d,0,0,0));
}

export function parseISOLocal(s) {
  var b = (s + 'T00:00:00').split(/\D/);
  return new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5]);
}

export function incDate(date, nbOfDays) {	
	const result = new Date(date);
	result.setDate(result.getDate() + nbOfDays);
	return result;
}