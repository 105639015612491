import useMetaTags from 'react-metatags-hook'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useMediaPredicate } from "react-media-hook";
import styles from './Home.module.css';
import text from '../i8n/text.js';

function Home({ language }) {
  useMetaTags({
    title: text.home.meta.title[language],
    description: text.home.meta.description[language],
  });
	const isSmall = useMediaPredicate("(max-width: 600px)");
	return ( 
	  <div>
  		<Carousel infiniteLoop autoPlay dynamicHeight interval={5000} showThumbs={false} transitionTime={1000} stopOnHover={false} showStatus={false}>
        <div>
          <img className="image-1" src={isSmall ? "/images/living_3.jpg" : "/images/living_3_home.jpg"} alt="" />
					<p className={styles.carouselText}>{text.home.pic1[language]}</p>
        </div>
        <div>
          <img className="image-2" src={isSmall ? "/images/zicht_4.jpg" : "/images/zicht_4_home.jpg"} alt="" />
		      <p className={styles.carouselText}>{text.home.pic2[language]}</p>
        </div>
        <div>
          <img className="image-3" src={isSmall ? "/images/9.jpg" : "/images/4.jpg"} alt="" />
		      <p className={styles.carouselText}>{text.home.pic3[language]}</p>
        </div>
      </Carousel>
	 	</div>
  );
}

export default Home;